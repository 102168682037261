body > header.touch {
	display: none;

	@include with-touch-nav {
		display: flex;
	}

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	// The menu toggle button
	> .toggle-touch-menu {
		flex-shrink: 0;

		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1rem;

		font-size: 2.6rem;
		cursor: pointer;

		background-color: #FFF;

		&::before {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1em;
			height: 1em;
			line-height: 1rem;

			font-family: FontAwesome;
			vertical-align: top;
			content: "\f0c9";
			color: $color-text;
		}
	}

	> .logo {
		flex-grow: 1;

		display: flex;
		padding: 0.325rem 2rem;

		background-color: $color-gray-light;

		a {
			flex-grow: 1;

			display: block;

			background-image: url('../images/header-logo.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	> .donate {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1.5rem 2rem 1.325rem;

		background-color: $color-blue-light;

		color: $color-text;
		font-family: $font-title;
		font-size: 1.4em;

		@media (max-width: 480px) {
			padding: 1rem 1.5rem 0.9rem;
			font-size: 1.2em;
		}
	}
}