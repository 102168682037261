main.page.index {

	.posts {

		article {
			position: relative;

			display: block;
			margin: 0 0 3rem;

			&:not(:last-of-type) {
				padding: 0 0 3rem;

				&::after {
					position: absolute;
					top: calc(100% - 0.125rem);
					right: -4rem;

					display: block;
					height: 0.25rem;
					width: 100vw;

					content: "";
					background-color: rgba($color-gray-light, 1);

					@media (max-width: 800px) {
						right: inherit;
						left: 0;

						width: 100%;
					}
				}
			}

			.entry-title {
				display: block;
				margin: 0;

				font-size: 2rem;
				line-height: 1.4em;
			}

			.entry-meta {
				color: $color-gray-dark;
				font-size: 0.9em;
			}

			.excerpt {

				p {
					margin: 0;
				}
			}
		}
	}
}