$color-blue-background: #dfedf2;
$color-blue-light: #44c7f4;
$color-blue: #00adee;
$color-blue-dark: #149bcc;

$color-smalt: #5b808c;
$color-teal: #033e54;

$color-melon: #f48644;

$color-gray-light: #e6e6e6;
$color-gray: #CCC;
$color-gray-dark: #AAA;

$color-text: #3a3a3a;

$color-footer-background: #3a3a3a;

$color-error: #e53935;
$color-error-bg: rgba($color-error, 0.2);