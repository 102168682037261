main.page .sidebar {
	display: flex;
	flex-direction: column;

	> header {
		// margin-bottom: 2rem;

		.image {
			height: 240px;

			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}

	> section {
		padding: 0 0 0 2rem;
	}

	.widget-title {
		font-size: 1.2rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	.widget {
		padding: 2rem 2rem 2rem 0;

		// border: 1px solid blue;

		&:not(:last-of-type) {
			// margin-bottom: 1rem;

			border-bottom: 0.5rem solid $color-gray;
		}
	}

	.widget_gravity_form {

		.gfield {
			padding-right: 0;
			margin-bottom: 1.5rem;

			&.gfield_error {
				flex-direction: column;
			}
		}

		input[type="submit"] {
			display: block;
			width: 100%;
			padding: 1rem;
		}
	}

	.widget_recent_entries {

		ul {

			li {
				display: block;

				&:not(:last-of-type) {
					border-bottom: 1px solid $color-gray;
				}

				a {
					display: block;
					padding: 0.625rem 0;

					color: $color-text;
				}
			}
		}
	}
}