gecko-slideshow-controls {
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;

	color: white;

	pointer-events: none;

	> * {
		pointer-events: all;
	}

	@mixin icon($code) {
		& {
			display: inline-flex;
			align-items: center;
			justify-content: center;

			width: 1.2em;
			height: 1.2em;
			color: white;
			font-size: 4rem;

			&::before {
				font-family: FontAwesome;
				content: $code;
			}
		}
	}

	.dots {
		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;
		left: 0;
		right: 0;
		// top: calc(100% - 40px - 0.5rem);
		bottom: 0.5rem;

		> .dot {
			flex-shrink: 0;

			width: 0.8rem;
			height: 0.8rem;
			margin: 0.5rem;

			cursor: pointer;

			border-radius: 100%;
			background-color: $color-blue-dark;
			// box-shadow: 1px 2px 4px 0px rgba(black, 0.2);
			opacity: 0.4;

			transition: opacity 0.2s ease-in-out;

			&[data-active] {
				opacity: 1;
			}
		}
	}

	.left, .right {
		position: absolute;

		transform: translateY(-50%);

		transition: transform 0.2s ease-in-out;
	}

	.left {
		@include icon("\f104");

		text-shadow: -1px 1px 1px rgba(black, 1);
		left: 0;
		top: 50%;

		&:active {
			transform: translateY(-50%) translateX(-6px);
		}
	}

	.right {
		@include icon("\f105");

		text-shadow: 1px 1px 1px rgba(black, 1);
		right: 0;
		top: 50%;

		&:active {
			transform: translateY(-50%) translateX(6px);
		}
	}
}