@charset "UTF-8";
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
@import url("//fonts.googleapis.com/css?family=Fjalla+One|Lato:300,400");
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 *
 * Improved by Gecko Designs to reset ALL the things!
 */
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: none;
  vertical-align: baseline; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  text-decoration: none; }

main > .clamped-width {
  max-width: 100%;
  margin: 0 auto; }

/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */
/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
/* Touch Nav Detection (1.1.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */
body {
  font-family: "Lato", sans-serif;
  color: #3a3a3a;
  background-color: #3a3a3a; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fjalla One", sans-serif;
  margin-bottom: 1rem;
  line-height: 1.4em; }

a {
  outline: none;
  color: #f48644; }

main.page {
  background-color: #fff; }

main.page:not(.front) {
  position: relative;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  main.page:not(.front) .primary {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 36rem;
    flex-basis: 36rem;
    margin-top: -2rem;
    padding: 2rem 4rem 2rem 8.33333%;
    background-color: #fff; }
    @media (max-width: 768px) {
      main.page:not(.front) .primary {
        padding: 2rem; } }
    @media (max-width: 460px) {
      main.page:not(.front) .primary {
        padding: 1rem; } }
    main.page:not(.front) .primary .column-wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -1rem; }
      main.page:not(.front) .primary .column-wrapper > .column {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 24rem;
        flex-basis: 24rem;
        margin: 0 1rem 1rem; }
  main.page:not(.front) .sidebar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #e6e6e6; }
    @media (max-width: 52rem) {
      main.page:not(.front) .sidebar {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        width: 100%; } }

.page-header {
  position: relative;
  z-index: 9;
  padding: 6rem 0 8rem;
  background-image: url("../images/page-header-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 640px) {
    .page-header {
      padding: 2rem 0 4rem; } }
  .page-header::before {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    content: "";
    background-color: rgba(20, 155, 204, 0.6); }
  .page-header h1 {
    position: relative;
    z-index: 10;
    margin: 0;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    font-size: 3rem; }

.button,
button {
  display: inline-block;
  padding: 1.5rem 3rem;
  color: #3a3a3a;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #f48644;
  transition: background 0.2s ease; }
  .button:hover,
  button:hover {
    background-color: #f5965c; }

input[type="text"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
textarea {
  padding: 0.5rem 0;
  width: 100%;
  border: 0;
  outline: none; }

.gform_validation_container {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.gform_fields {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.gfield {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.625rem 0;
  padding-right: 2rem;
  width: 100%;
  max-width: 100%; }
  @media (max-width: 768px) {
    .gfield {
      padding-right: 0; } }
  .gfield.gfield_error {
    padding-right: 0;
    border: 1px solid rgba(229, 57, 53, 0.2); }
    .gfield.gfield_error .validation_message {
      -ms-flex-item-align: start;
      align-self: flex-start;
      margin: 0 0 0 0.5rem;
      padding: 0.5rem;
      font-size: 0.9rem;
      line-height: 1.1rem;
      color: #e53935;
      background-color: rgba(229, 57, 53, 0.2); }
  @media (min-width: 768px) {
    .gfield.half-col {
      width: 50%; } }
  .gfield label {
    font-size: 1rem;
    color: #3a3a3a;
    transition: all 0.3s ease; }
  .gfield select {
    min-width: 300px; }
  .gfield[data-animate] label {
    position: absolute;
    top: 0.5rem; }
  .gfield .gfield_description {
    font-size: 0.9rem;
    font-style: italic; }
  .gfield .clear-multi {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .gfield .clear-multi > * {
      margin: 0 1rem 1rem; }
    .gfield .clear-multi select {
      border: 1px solid #000; }
  .gfield .ginput_container {
    position: relative;
    width: 100%; }
    .gfield .ginput_container::before, .gfield .ginput_container::after {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      content: ""; }
    .gfield .ginput_container::before {
      z-index: 90;
      width: 100%;
      border-bottom: 1px solid #3a3a3a; }
    .gfield .ginput_container::after {
      z-index: 100;
      left: 50%;
      width: 0;
      transition: all 0.2s ease;
      border-bottom: 2px solid #f48644; }
    .gfield .ginput_container.ginput_container_radio::before, .gfield .ginput_container.ginput_container_radio::after, .gfield .ginput_container.ginput_container_checkbox::before, .gfield .ginput_container.ginput_container_checkbox::after {
      display: none; }
    .gfield .ginput_container.ginput_container_radio > ul, .gfield .ginput_container.ginput_container_checkbox > ul {
      list-style: none;
      margin: 0;
      padding: 0.5rem 0 0 0; }
      .gfield .ginput_container.ginput_container_radio > ul li, .gfield .ginput_container.ginput_container_checkbox > ul li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 0 0.25rem; }
    .gfield .ginput_container.ginput_container_radio label, .gfield .ginput_container.ginput_container_checkbox label {
      position: relative;
      top: inherit; }
    .gfield .ginput_container.ginput_container_radio input[type="radio"],
    .gfield .ginput_container.ginput_container_radio input[type="checkbox"], .gfield .ginput_container.ginput_container_checkbox input[type="radio"],
    .gfield .ginput_container.ginput_container_checkbox input[type="checkbox"] {
      display: inline-block;
      margin-right: 0.5rem; }
    .gfield .ginput_container.ginput_container_date, .gfield .ginput_container.ginput_container_name {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .gfield .ginput_container.ginput_container_date::before, .gfield .ginput_container.ginput_container_name::before {
        display: none; }
      .gfield .ginput_container.ginput_container_date span, .gfield .ginput_container.ginput_container_name span {
        display: block; }
        @media (min-width: 768px) {
          .gfield .ginput_container.ginput_container_date span, .gfield .ginput_container.ginput_container_name span {
            width: 50%; } }
        .gfield .ginput_container.ginput_container_date span:not(:last-of-type), .gfield .ginput_container.ginput_container_name span:not(:last-of-type) {
          padding-right: 2rem; }
      .gfield .ginput_container.ginput_container_date input, .gfield .ginput_container.ginput_container_name input {
        border-bottom: 1px solid #000; }
    .gfield .ginput_container.ginput_container_address {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .gfield .ginput_container.ginput_container_address span {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: block; }
        @media (min-width: 768px) {
          .gfield .ginput_container.ginput_container_address span {
            width: 50%; } }
        .gfield .ginput_container.ginput_container_address span:not(:last-of-type) {
          padding-right: 2rem; }
      .gfield .ginput_container.ginput_container_address select {
        display: block;
        width: 100%;
        border: 1px solid #000; }
      .gfield .ginput_container.ginput_container_address input {
        border-bottom: 1px solid #000; }
    .gfield .ginput_container.ginput_container_time::before {
      display: none; }
    .gfield .ginput_container.ginput_container_time input {
      border-bottom: 1px solid #000; }
  .gfield[data-animate][data-status="focus"] .ginput_container::after {
    left: 0;
    width: 100%; }
  .gfield[data-animate][data-status="focus"] label, .gfield[data-animate][data-filled="true"] label {
    transform: translateY(-1rem);
    font-size: 0.8rem; }
  .gfield[data-animate][data-status="focus"] label {
    color: #f48644; }

.ui-datepicker {
  background-color: #fff; }
  .ui-datepicker .ui-datepicker-header {
    padding: 0.25rem;
    font-size: 1rem;
    background-color: #5b808c;
    text-align: center; }
    .ui-datepicker .ui-datepicker-header a {
      padding: 4px 8px; }
  .ui-datepicker .ui-datepicker-calendar thead tr th {
    padding: 4px;
    color: #333;
    font-size: 1rem; }
  .ui-datepicker .ui-datepicker-calendar tbody tr td {
    text-align: center;
    font-size: 1rem;
    border: 1px solid #5b808c; }
    .ui-datepicker .ui-datepicker-calendar tbody tr td a {
      display: block;
      padding: 5px; }
      .ui-datepicker .ui-datepicker-calendar tbody tr td a a {
        color: #00adee; }
      .ui-datepicker .ui-datepicker-calendar tbody tr td a.ui-state-active {
        color: #333;
        font-weight: 700; }

gecko-blackout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; }
  gecko-blackout:not([hidden]) {
    transition-delay: 0s; }
  gecko-blackout[hidden] {
    visibility: hidden;
    opacity: 0; }

gecko-slide {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  gecko-slide[data-state="in"] {
    z-index: 2;
    opacity: 1; }

gecko-slideshow-controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;
  pointer-events: none; }
  gecko-slideshow-controls > * {
    pointer-events: all; }
  gecko-slideshow-controls .dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.5rem; }
    gecko-slideshow-controls .dots > .dot {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin: 0.5rem;
      cursor: pointer;
      border-radius: 100%;
      background-color: #149bcc;
      opacity: 0.4;
      transition: opacity 0.2s ease-in-out; }
      gecko-slideshow-controls .dots > .dot[data-active] {
        opacity: 1; }
  gecko-slideshow-controls .left, gecko-slideshow-controls .right {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out; }
  gecko-slideshow-controls .left {
    text-shadow: -1px 1px 1px black;
    left: 0;
    top: 50%; }
    gecko-slideshow-controls .left {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .left::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .left:active {
      transform: translateY(-50%) translateX(-6px); }
  gecko-slideshow-controls .right {
    text-shadow: 1px 1px 1px black;
    right: 0;
    top: 50%; }
    gecko-slideshow-controls .right {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow-controls .right::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow-controls .right:active {
      transform: translateY(-50%) translateX(6px); }

gecko-slideshow {
  display: block;
  position: relative;
  z-index: 1; }
  gecko-slideshow:not([data-bound]) {
    visibility: hidden; }
  gecko-slideshow > .viewport {
    width: 100%;
    height: 600px; }
    gecko-slideshow > .viewport > .slides {
      position: relative;
      width: 100%;
      height: 100%; }
  gecko-slideshow .overlay {
    position: absolute;
    z-index: 1000;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    height: 66%;
    padding: 2rem 2rem 3rem;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8)); }
    gecko-slideshow .overlay .caption {
      display: block;
      margin: 0 0 2rem;
      text-align: center;
      font-family: "Fjalla One", sans-serif;
      font-size: 3rem; }
      @media (max-width: 768px) {
        gecko-slideshow .overlay .caption {
          font-size: 2rem; } }

.luvu-slider__container {
  height: 600px;
  background-color: #3a3a3a; }

.luvu-slider__slide {
  position: relative;
  box-sizing: border-box; }

.luvu-slider__image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: inherit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .luvu-slider__image::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8)); }

.luvu-slider__wash {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0; }

.luvu-slider__content {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  padding: 3rem 5rem;
  width: 100%;
  height: 100%;
  color: #3a3a3a; }

.luvu-slider__position {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .luvu-slider__position--left-bottom {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: left; }
  .luvu-slider__position--center-bottom {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: center; }
  .luvu-slider__position--right-bottom {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: right; }
  .luvu-slider__position--left-center {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left; }
  .luvu-slider__position--center-center {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
  .luvu-slider__position--right-center {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: right; }
  .luvu-slider__position--left-top {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left; }
  .luvu-slider__position--center-top {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center; }
  .luvu-slider__position--right-top {
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: right; }

.luvu-slider__headline {
  margin: 0;
  max-width: 66%;
  font-family: "Fjalla One", sans-serif;
  font-size: 3rem; }
  .luvu-slider__headline:not(:last-child) {
    margin: 0 0 1rem; }
  @media (max-width: 768px) {
    .luvu-slider__headline {
      max-width: 100%;
      font-size: 2rem; } }

.luvu-slider__button {
  display: inline-block;
  padding: 1.5rem 3rem;
  color: #3a3a3a;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #f48644;
  transition: background 0.2s ease; }
  .luvu-slider__button:hover {
    background-color: #f5965c; }

.luvu-slider__nav {
  position: absolute;
  z-index: 3;
  top: 0;
  width: 4rem;
  height: 100%;
  transition: all 0.2s ease-in-out;
  cursor: pointer; }
  .luvu-slider__nav::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    font-family: fontawesome;
    font-size: 2.6rem;
    color: #FFF;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
    opacity: 0; }
  .luvu-slider__nav:hover {
    background-color: rgba(0, 0, 0, 0.3); }
    .luvu-slider__nav:hover::before {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1; }
  .luvu-slider__nav--previous {
    left: 0; }
    .luvu-slider__nav--previous::before {
      content: "\f104"; }
  .luvu-slider__nav--next {
    right: 0; }
    .luvu-slider__nav--next::before {
      content: "\f105"; }

.luvu-slider__pagination {
  position: absolute;
  z-index: 3;
  bottom: 0.5rem;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%; }
  .luvu-slider__pagination .swiper-pagination-bullet {
    display: block;
    margin: 0 0.5rem;
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
    border-radius: 0.75rem;
    background-color: rgba(153, 153, 153, 0.5); }
    .luvu-slider__pagination .swiper-pagination-bullet-active {
      background-color: #149bcc; }

.fact {
  display: -ms-flexbox;
  display: flex;
  background-color: #44c7f4; }
  @media (max-width: 480px) {
    .fact {
      -ms-flex-direction: column;
      flex-direction: column; } }
  .fact .icon {
    position: relative;
    min-height: 10rem;
    min-width: 12rem;
    background-color: #00adee; }
    @media (max-width: 480px) {
      .fact .icon {
        min-height: 5rem;
        min-width: 5rem; } }
    .fact .icon::before {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #149bcc;
      font-family: fontawesome;
      font-size: 10rem;
      content: "\f05a"; }
      @media (max-width: 480px) {
        .fact .icon::before {
          font-size: 5rem; } }
    .fact .icon::after {
      position: absolute;
      z-index: 20;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: "Fact:";
      color: #FFF;
      font-size: 3.6rem;
      letter-spacing: 2px;
      font-family: "Fjalla One", sans-serif;
      text-transform: uppercase; }
      @media (max-width: 480px) {
        .fact .icon::after {
          font-size: 2.8rem; } }
  .fact .text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 2rem;
    font-size: 1.625rem; }
    @media (max-width: 480px) {
      .fact .text {
        padding: 1rem 1rem 2rem;
        font-size: 1.2rem;
        text-align: center; } }

body > footer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 4rem 2rem;
  color: #FFF;
  background-color: #3a3a3a; }
  @media (max-width: 900px) {
    body > footer {
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
      padding: 2rem; } }
  body > footer #back-to-top {
    position: absolute;
    top: -1rem;
    left: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 2rem;
    width: 5rem;
    transform: translateX(-50%);
    background-color: #FFF; }
    body > footer #back-to-top::before {
      font-family: fontawesome;
      content: "\f106";
      font-size: 1.8rem; }
  body > footer .colophon {
    width: 33%; }
    @media (max-width: 900px) {
      body > footer .colophon {
        width: 100%;
        margin: 0 0 1rem; } }
  body > footer nav {
    display: -ms-flexbox;
    display: flex;
    width: 34%; }
    @media (max-width: 900px) {
      body > footer nav {
        width: 100%;
        margin: 0 0 1rem; } }
    body > footer nav > div {
      width: 100%; }
    body > footer nav ul {
      text-align: center; }
    body > footer nav ul > li {
      display: inline-block; }
      @media (max-width: 900px) {
        body > footer nav ul > li {
          display: block; } }
      body > footer nav ul > li:not(:last-of-type) {
        margin-right: 0.325rem;
        padding-right: 0.325rem; }
        body > footer nav ul > li:not(:last-of-type)::after {
          padding-left: 0.75rem;
          content: "/"; }
        @media (max-width: 900px) {
          body > footer nav ul > li:not(:last-of-type) {
            margin-right: 0;
            padding-right: 0;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem; }
            body > footer nav ul > li:not(:last-of-type)::after {
              display: none; } }
  body > footer .attribution {
    width: 33%;
    text-align: right; }
    @media (max-width: 900px) {
      body > footer .attribution {
        width: 100%;
        text-align: center;
        font-size: 0.9em; } }

body > header.desktop {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-color: #e6e6e6; }
  @media screen and (max-width: 1024px) {
    body > header.desktop {
      display: none; } }
  html.touch body > header.desktop {
    display: none; }
  body > header.desktop a.logo {
    display: block;
    width: 400px;
    min-width: 360px;
    min-height: 100px;
    font-size: 0;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    background-image: url(../images/header-logo.png); }
    @media (max-width: 1024px) {
      body > header.desktop a.logo {
        width: 240px;
        min-width: 240px;
        background-size: 90% auto; } }
  body > header.desktop a:not(.logo) {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1rem 1rem;
    color: #3a3a3a;
    font-family: "Fjalla One", sans-serif;
    text-align: center;
    transition: all 0.2s ease; }
    body > header.desktop a:not(.logo)::before {
      display: block;
      margin-bottom: 0.625rem;
      font-size: 1.6rem;
      font-family: fontawesome;
      color: #CCC;
      content: "i";
      transition: all 0.2s ease; }
    body > header.desktop a:not(.logo):hover {
      background-color: #CCC; }
      body > header.desktop a:not(.logo):hover::before {
        color: #f48644; }
  body > header.desktop a.donate {
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #44c7f4; }
    body > header.desktop a.donate::before {
      content: "\f004";
      color: #f48644; }
    body > header.desktop a.donate:hover {
      background-color: #00adee; }
  body > header.desktop nav {
    display: -ms-flexbox;
    display: flex; }
    body > header.desktop nav.primary {
      -ms-flex-positive: 1;
      flex-grow: 1; }
      body > header.desktop nav.primary > div {
        display: -ms-flexbox;
        display: flex;
        width: 100%; }
      body > header.desktop nav.primary .menu {
        display: -ms-flexbox;
        display: flex;
        width: 100%; }
        body > header.desktop nav.primary .menu > li {
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-positive: 1;
          flex-grow: 1; }
          body > header.desktop nav.primary .menu > li.info-circle > a::before {
            content: "\f05a"; }
          body > header.desktop nav.primary .menu > li.list > a::before {
            content: "\f0ca"; }
          body > header.desktop nav.primary .menu > li.newspaper > a::before {
            content: "\f1ea"; }
          body > header.desktop nav.primary .menu > li.calendar > a::before {
            content: "\f073"; }
          body > header.desktop nav.primary .menu > li.user-plus > a::before {
            content: "\f234"; }
          body > header.desktop nav.primary .menu > li.envelope > a::before {
            content: "\f0e0"; }
          body > header.desktop nav.primary .menu > li.search > a::before {
            content: "\f002"; }
          body > header.desktop nav.primary .menu > li.current-menu-item > a::after {
            position: absolute;
            bottom: 0rem;
            left: calc(50% - 0.5rem);
            content: "";
            border-top: 0;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid #f48644; }
          body > header.desktop nav.primary .menu > li > .sub-menu {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            min-width: 200px; }
            body > header.desktop nav.primary .menu > li > .sub-menu a {
              display: -ms-flexbox;
              display: flex;
              width: 100%;
              -ms-flex-align: start;
              align-items: flex-start;
              padding: 1rem;
              text-align: left;
              line-height: 1.2;
              background-color: #cccccc; }
              body > header.desktop nav.primary .menu > li > .sub-menu a::before {
                display: none; }
              body > header.desktop nav.primary .menu > li > .sub-menu a:hover {
                background-color: #b3b3b3; }
            body > header.desktop nav.primary .menu > li > .sub-menu .sub-menu a {
              padding-left: 2rem;
              font-size: 0.9em; }
          body > header.desktop nav.primary .menu > li:hover > a {
            background-color: #CCC; }
            body > header.desktop nav.primary .menu > li:hover > a::before {
              color: #f48644; }
          body > header.desktop nav.primary .menu > li:hover > .sub-menu {
            display: block; }
    body > header.desktop nav.social {
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -ms-flex-direction: column;
      flex-direction: column; }
      body > header.desktop nav.social a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 1rem;
        font-size: 0;
        color: transparent; }
        body > header.desktop nav.social a::before {
          display: -ms-flexbox;
          display: flex;
          margin: 0;
          font-family: fontawesome;
          content: "";
          font-size: 1.2rem;
          color: #3a3a3a; }
        body > header.desktop nav.social a:hover {
          background-color: #e6e6e6; }
        body > header.desktop nav.social a.facebook::before {
          content: "\f09a"; }
        body > header.desktop nav.social a.instagram::before {
          content: "\f16d"; }
        body > header.desktop nav.social a.twitter::before {
          content: "\f099"; }
  body > header.desktop .search-popup {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 4rem;
    height: 100vh;
    width: 100%;
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s linear; }
    body > header.desktop .search-popup[data-status="active"] {
      visibility: visible;
      transform: translateY(0);
      opacity: 1; }
    body > header.desktop .search-popup .overlay {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 173, 238, 0.8); }
    body > header.desktop .search-popup .close {
      position: absolute;
      z-index: 20;
      top: 0;
      right: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0rem;
      width: 6rem;
      height: 6rem;
      color: #fff;
      font-size: 6rem;
      line-height: 6rem;
      background-color: transparent; }
    body > header.desktop .search-popup .input-wrapper {
      position: relative;
      z-index: 20;
      display: -ms-flexbox;
      display: flex;
      margin: 0 0 2rem;
      width: 100%; }
      body > header.desktop .search-popup .input-wrapper input[type="text"] {
        display: block;
        padding: 1rem;
        text-align: center;
        font-size: 2rem;
        background-color: rgba(255, 255, 255, 0.9); }

body > header.touch {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; }
  @media screen and (max-width: 1024px) {
    body > header.touch {
      display: -ms-flexbox;
      display: flex; } }
  html.touch body > header.touch {
    display: -ms-flexbox;
    display: flex; }
  body > header.touch > .toggle-touch-menu {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1rem;
    font-size: 2.6rem;
    cursor: pointer;
    background-color: #FFF; }
    body > header.touch > .toggle-touch-menu::before {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      width: 1em;
      height: 1em;
      line-height: 1rem;
      font-family: FontAwesome;
      vertical-align: top;
      content: "\f0c9";
      color: #3a3a3a; }
  body > header.touch > .logo {
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    padding: 0.325rem 2rem;
    background-color: #e6e6e6; }
    body > header.touch > .logo a {
      -ms-flex-positive: 1;
      flex-grow: 1;
      display: block;
      background-image: url("../images/header-logo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
  body > header.touch > .donate {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1.5rem 2rem 1.325rem;
    background-color: #44c7f4;
    color: #3a3a3a;
    font-family: "Fjalla One", sans-serif;
    font-size: 1.4em; }
    @media (max-width: 480px) {
      body > header.touch > .donate {
        padding: 1rem 1.5rem 0.9rem;
        font-size: 1.2em; } }

main.page .sidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  main.page .sidebar > header .image {
    height: 240px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  main.page .sidebar > section {
    padding: 0 0 0 2rem; }
  main.page .sidebar .widget-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase; }
  main.page .sidebar .widget {
    padding: 2rem 2rem 2rem 0; }
    main.page .sidebar .widget:not(:last-of-type) {
      border-bottom: 0.5rem solid #CCC; }
  main.page .sidebar .widget_gravity_form .gfield {
    padding-right: 0;
    margin-bottom: 1.5rem; }
    main.page .sidebar .widget_gravity_form .gfield.gfield_error {
      -ms-flex-direction: column;
      flex-direction: column; }
  main.page .sidebar .widget_gravity_form input[type="submit"] {
    display: block;
    width: 100%;
    padding: 1rem; }
  main.page .sidebar .widget_recent_entries ul li {
    display: block; }
    main.page .sidebar .widget_recent_entries ul li:not(:last-of-type) {
      border-bottom: 1px solid #CCC; }
    main.page .sidebar .widget_recent_entries ul li a {
      display: block;
      padding: 0.625rem 0;
      color: #3a3a3a; }

body > aside.touch-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  width: 80vw;
  max-width: 25rem;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
  transition: left 0.3s ease-in-out; }
  body > aside.touch-menu[hidden] {
    left: -100%; }
  body > aside.touch-menu header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 1rem;
    border-bottom: 1px solid black; }
  body > aside.touch-menu nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto; }
    body > aside.touch-menu nav .menu > li {
      display: block; }
      body > aside.touch-menu nav .menu > li:not(:last-of-type) {
        border-bottom: 1px solid #CCC; }
      body > aside.touch-menu nav .menu > li a {
        display: block;
        transition: background-color 0.2s ease-in-out; }
      body > aside.touch-menu nav .menu > li > a {
        padding: 1rem;
        font-size: 1.4rem; }
        body > aside.touch-menu nav .menu > li > a:focus, body > aside.touch-menu nav .menu > li > a:hover {
          background-color: rgba(204, 204, 204, 0.2); }
        body > aside.touch-menu nav .menu > li > a:active {
          background-color: rgba(0, 0, 0, 0.3); }
      body > aside.touch-menu nav .menu > li .sub-menu > li {
        border-top: 1px solid rgba(204, 204, 204, 0.8); }
        body > aside.touch-menu nav .menu > li .sub-menu > li > a {
          padding: 0.5rem 1.75rem;
          background-color: rgba(204, 204, 204, 0.2); }
          body > aside.touch-menu nav .menu > li .sub-menu > li > a:focus, body > aside.touch-menu nav .menu > li .sub-menu > li > a:hover {
            background-color: rgba(204, 204, 204, 0.4); }
  body > aside.touch-menu footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-top: 1px solid black;
    padding: 1rem; }

.wysiwyg h1 {
  font-size: 3rem; }

.wysiwyg h2 {
  font-size: 2.3rem; }

.wysiwyg h3 {
  font-size: 1.9rem; }

.wysiwyg h4 {
  font-size: 1.4rem; }

.wysiwyg p {
  line-height: 1.6;
  margin: 1rem 0; }

.wysiwyg b, .wysiwyg strong {
  font-weight: bold; }

.wysiwyg i, .wysiwyg em {
  font-style: italic; }

.wysiwyg ul {
  list-style: disc;
  margin-left: 1rem; }
  .wysiwyg ul.gform_fields {
    margin-left: 0; }

.wysiwyg ol {
  counter-reset: ol; }
  .wysiwyg ol > li {
    counter-increment: ol; }
    .wysiwyg ol > li::before {
      content: counter(ol);
      margin-right: 0.5rem; }

.wysiwyg ul li,
.wysiwyg ol li {
  line-height: 1.6; }
  .wysiwyg ul li:not(:last-of-type),
  .wysiwyg ol li:not(:last-of-type) {
    margin-bottom: 1rem; }

.wysiwyg hr {
  position: relative;
  height: 0;
  margin: 3rem 0;
  border: 0;
  border-bottom: 0.5rem solid #e6e6e6; }
  .wysiwyg hr::before, .wysiwyg hr::after {
    position: absolute;
    top: 0;
    display: block;
    height: 0.5rem;
    content: "";
    background-color: #e6e6e6; }
  .wysiwyg hr::before {
    right: 100%;
    width: 50vw; }
  .wysiwyg hr::after {
    left: 100%;
    width: 4rem; }
  @media (max-width: 768px) {
    .wysiwyg hr::before, .wysiwyg hr::after {
      width: 2rem; } }
  @media (max-width: 460px) {
    .wysiwyg hr::before, .wysiwyg hr::after {
      width: 1rem; } }

.wysiwyg .pullquote {
  position: relative;
  padding: 2rem 2rem 2rem 0;
  font-size: 1.4rem;
  font-style: italic;
  line-height: 1.6em;
  background-color: #dfedf2; }
  .wysiwyg .pullquote::before {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    content: "";
    background-color: #dfedf2; }
  .wysiwyg .pullquote::before {
    right: 100%;
    width: 50vw; }

.wysiwyg .alignright {
  float: right;
  margin: 2rem 0 2rem 2rem; }

.wysiwyg .alignleft {
  float: left;
  margin: 2rem 2rem 2rem 0; }

.wysiwyg .wp-caption-text {
  padding: 0.5rem 1rem;
  font-size: 0.9em;
  text-align: center; }

.wysiwyg img {
  max-width: 100%;
  height: auto; }

.wysiwyg .gform_wrapper .validation_error {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  color: #e53935;
  background-color: rgba(229, 57, 53, 0.2); }

.pagination {
  display: -ms-flexbox;
  display: flex; }
  .pagination > * {
    display: inline-block;
    padding: 1rem; }
    .pagination > *:not(:last-child) {
      margin-right: 1rem; }
  .pagination span {
    cursor: default;
    color: #AAA;
    background-color: #e6e6e6; }
    .pagination span.dots {
      padding: 1rem 0.5rem;
      background-color: transparent; }
  .pagination a {
    color: #FFF;
    background-color: #f48644;
    transition: background 0.2s ease; }
    .pagination a:hover {
      background-color: #f5965c; }

/* Swiper.js */
/* stylelint-disable */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-invisible-blank-slide {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -ms-flex-align: start;
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent); }

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube {
  overflow: visible; }

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  filter: blur(50px);
  z-index: 0; }

.swiper-container-flip {
  overflow: visible; }

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

main.front .featured {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 900px) {
    main.front .featured {
      -ms-flex-pack: start;
      justify-content: flex-start;
      background-position: top left;
      background-size: auto; } }
  main.front .featured::before {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    content: "";
    background-image: linear-gradient(to right, rgba(223, 237, 242, 0), rgba(223, 237, 242, 0) 33%, rgba(223, 237, 242, 0.9) 66%, #dfedf2); }
    @media (max-width: 900px) {
      main.front .featured::before {
        font-size: 2.8rem;
        background-image: linear-gradient(to bottom, rgba(223, 237, 242, 0), rgba(223, 237, 242, 0) 33%, #dfedf2 66%, #dfedf2); } }
  main.front .featured .content {
    position: relative;
    z-index: 20;
    width: 40%;
    padding: 4rem 2rem 4rem 0; }
    @media (max-width: 900px) {
      main.front .featured .content {
        padding: 22rem 2rem 2rem;
        width: 100%; } }
    main.front .featured .content h1 {
      font-size: 2.5rem;
      color: #00adee; }
      @media (max-width: 768px) {
        main.front .featured .content h1 {
          font-size: 2rem; } }
    main.front .featured .content p {
      margin: 0 0 2rem;
      font-size: 1.2rem;
      line-height: 2em; }
      @media (max-width: 900px) {
        main.front .featured .content p {
          line-height: 1.4em; } }

main.front .sign-up {
  display: -ms-flexbox;
  display: flex;
  padding: 2rem; }
  main.front .sign-up .icon {
    margin-right: 2rem; }
    main.front .sign-up .icon::before {
      content: "\f0f6";
      font-size: 6rem;
      font-family: fontawesome;
      color: #dfedf2; }
    @media (max-width: 900px) {
      main.front .sign-up .icon {
        display: none; } }
  main.front .sign-up .gform_wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    main.front .sign-up .gform_wrapper > form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin: 0; }
      @media (max-width: 900px) {
        main.front .sign-up .gform_wrapper > form {
          -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }
      main.front .sign-up .gform_wrapper > form .gform_heading {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 2rem; }
        @media (max-width: 900px) {
          main.front .sign-up .gform_wrapper > form .gform_heading {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem; } }
        main.front .sign-up .gform_wrapper > form .gform_heading .gform_title {
          margin: 0;
          color: #00adee;
          font-size: 1.625rem; }
      main.front .sign-up .gform_wrapper > form .gform_body {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-positive: 1;
        flex-grow: 1; }
        @media (max-width: 900px) {
          main.front .sign-up .gform_wrapper > form .gform_body {
            margin-bottom: 1rem; } }
        main.front .sign-up .gform_wrapper > form .gform_body .gform_fields {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          width: 100%; }
          @media (max-width: 480px) {
            main.front .sign-up .gform_wrapper > form .gform_body .gform_fields {
              -ms-flex-direction: column;
              flex-direction: column; } }
          main.front .sign-up .gform_wrapper > form .gform_body .gform_fields label .gfield_required {
            display: none; }
      main.front .sign-up .gform_wrapper > form .gform_footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center; }

main.front .events-news {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  main.front .events-news > div {
    position: relative;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    padding: 5rem 4rem;
    background-size: cover; }
    @media (max-width: 900px) {
      main.front .events-news > div {
        padding: 2rem 1rem; } }
  main.front .events-news > .events {
    padding-left: 8rem;
    background-image: url(../images/events-background.jpg);
    background-position: right center; }
    @media (max-width: 900px) {
      main.front .events-news > .events {
        padding-left: 1rem; } }
    main.front .events-news > .events h2::before {
      content: "\f073"; }
  main.front .events-news > .news {
    padding-left: 10rem;
    background-image: url(../images/news-background.jpg);
    background-position: left center; }
    @media (max-width: 900px) {
      main.front .events-news > .news {
        padding-left: 1rem; } }
    main.front .events-news > .news h2::before {
      content: "\f1ea"; }
  main.front .events-news h2 {
    position: relative;
    margin: 0 0 1.5rem;
    font-size: 2.5rem;
    color: #FFF; }
    main.front .events-news h2::before {
      position: absolute;
      bottom: 0;
      right: calc(100% + 1.5rem);
      display: block;
      color: #00adee;
      font-size: 4rem;
      font-family: fontawesome; }
      @media (max-width: 900px) {
        main.front .events-news h2::before {
          display: none; } }
  main.front .events-news ul {
    margin: 0 0 2rem; }
    main.front .events-news ul > li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start; }
      main.front .events-news ul > li:not(:last-of-type) {
        margin-bottom: 2rem; }
      main.front .events-news ul > li.no-events {
        display: block;
        padding: 1rem;
        font-size: 1.6rem;
        background-color: #FFF; }
      main.front .events-news ul > li .date {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 1px;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        background-color: #dfedf2;
        cursor: default;
        font-family: "Fjalla One", sans-serif; }
        main.front .events-news ul > li .date .day {
          font-size: 3rem; }
        main.front .events-news ul > li .date .month {
          font-size: 1.6rem; }
      main.front .events-news ul > li .content {
        position: relative;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: block;
        padding: 1rem;
        padding-right: 4rem;
        background-color: #FFF;
        color: #3a3a3a; }
        main.front .events-news ul > li .content::after {
          position: absolute;
          top: 50%;
          right: 1.5rem;
          transform: translateY(-50%);
          transition: right 0.2s ease;
          font-size: 4rem;
          font-family: fontawesome;
          content: "\f105";
          color: #f48644; }
        main.front .events-news ul > li .content:hover::after {
          right: 1rem; }
        main.front .events-news ul > li .content .title {
          margin: 0 0 0.5rem;
          line-height: 1.2em;
          font-size: 1.625rem;
          font-family: "Fjalla One", sans-serif; }
        main.front .events-news ul > li .content .time {
          margin: 0 0 0.5rem; }
          main.front .events-news ul > li .content .time::before {
            display: inline-block;
            margin-right: 0.325rem;
            font-family: fontawesome;
            content: "\f017"; }
          main.front .events-news ul > li .content .time span {
            color: #00adee; }
        main.front .events-news ul > li .content .location::before {
          display: inline-block;
          margin-right: 0.325rem;
          font-family: fontawesome;
          content: "\f041"; }
        main.front .events-news ul > li .content .location span {
          color: #00adee; }
        main.front .events-news ul > li .content .read-more {
          color: #00adee; }
          main.front .events-news ul > li .content .read-more::before {
            display: inline-block;
            margin-right: 0.325rem;
            font-family: fontawesome;
            content: "\f06e";
            color: #3a3a3a; }

main.front .twitter-quote {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #dfedf2; }
  main.front .twitter-quote > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 30rem;
    flex-basis: 30rem;
    padding: 4rem; }
    @media (max-width: 900px) {
      main.front .twitter-quote > div {
        padding: 2rem; } }
  main.front .twitter-quote .twitter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    main.front .twitter-quote .twitter .twitter-feed {
      margin: 0 auto; }
  main.front .twitter-quote .quote {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
    main.front .twitter-quote .quote blockquote {
      position: relative;
      font-weight: 400;
      padding-left: 6rem; }
      @media (max-width: 900px) {
        main.front .twitter-quote .quote blockquote {
          margin-top: 6rem;
          padding-left: 0;
          text-align: center; } }
      main.front .twitter-quote .quote blockquote::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 5rem;
        width: 5rem;
        text-align: center;
        font-size: 15rem;
        line-height: 12rem;
        font-family: Helvetica, Arial, sans-serif;
        content: "“";
        color: #00adee; }
        @media (max-width: 900px) {
          main.front .twitter-quote .quote blockquote::before {
            top: inherit;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%); } }
      main.front .twitter-quote .quote blockquote p {
        margin: 0;
        font-size: 1.625rem;
        line-height: 1.6em; }
      main.front .twitter-quote .quote blockquote cite {
        display: block;
        padding: 1rem 0 0;
        font-size: 1rem;
        color: #00adee; }
        main.front .twitter-quote .quote blockquote cite::before {
          display: inline-block;
          margin-right: 0.5rem;
          content: "\2014"; }

main.front .infographic {
  padding: 6rem 2rem 4rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (max-width: 900px) {
    main.front .infographic {
      padding: 3rem 2rem 2rem; } }
  main.front .infographic .points {
    margin: 0 0 4rem; }
  main.front .infographic .point {
    position: relative;
    z-index: 100;
    margin: -2rem auto 0;
    padding: 8rem 2rem 2rem;
    width: 41.66667%;
    max-width: 100%;
    background-color: #FFF; }
    main.front .infographic .point svg.background {
      position: absolute;
      z-index: 8;
      top: 0;
      left: 0;
      display: block;
      height: 100%; }
      main.front .infographic .point svg.background image {
        width: 100%;
        height: 100%; }
    main.front .infographic .point .background-wash {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    main.front .infographic .point .content {
      position: relative;
      z-index: 10;
      line-height: 1.4em;
      color: #FFF;
      font-size: 1.625em;
      font-family: "Fjalla One", sans-serif;
      text-align: center; }
    main.front .infographic .point:nth-child(odd) {
      margin-left: 16.66667%; }
      @media (max-width: 1024px) {
        main.front .infographic .point:nth-child(odd) {
          width: 100%;
          margin-top: 0;
          margin-bottom: 1rem;
          margin-left: 0;
          margin-right: 0; } }
      main.front .infographic .point:nth-child(odd) svg.background {
        left: -2rem;
        width: calc(100% + 2rem); }
    main.front .infographic .point:nth-child(even) {
      margin-right: 16.66667%; }
      @media (max-width: 1024px) {
        main.front .infographic .point:nth-child(even) {
          width: 100%;
          margin-top: 0;
          margin-bottom: 1rem;
          margin-left: 0;
          margin-right: 0; } }
      main.front .infographic .point:nth-child(even) svg.background {
        left: 0;
        width: calc(100% + 2rem); }
    main.front .infographic .point:nth-child(2) {
      margin-right: 12.5%; }
      @media (max-width: 1024px) {
        main.front .infographic .point:nth-child(2) {
          margin-right: 0; } }
    main.front .infographic .point:nth-child(3) {
      margin-left: 12.5%; }
      @media (max-width: 1024px) {
        main.front .infographic .point:nth-child(3) {
          margin-left: 0; } }
  main.front .infographic footer {
    text-align: center; }
    main.front .infographic footer h2 {
      margin-bottom: 2rem;
      font-size: 3.75rem; }
      @media (max-width: 900px) {
        main.front .infographic footer h2 {
          font-size: 2.6rem; } }

main.page.index .posts article {
  position: relative;
  display: block;
  margin: 0 0 3rem; }
  main.page.index .posts article:not(:last-of-type) {
    padding: 0 0 3rem; }
    main.page.index .posts article:not(:last-of-type)::after {
      position: absolute;
      top: calc(100% - 0.125rem);
      right: -4rem;
      display: block;
      height: 0.25rem;
      width: 100vw;
      content: "";
      background-color: #e6e6e6; }
      @media (max-width: 800px) {
        main.page.index .posts article:not(:last-of-type)::after {
          right: inherit;
          left: 0;
          width: 100%; } }
  main.page.index .posts article .entry-title {
    display: block;
    margin: 0;
    font-size: 2rem;
    line-height: 1.4em; }
  main.page.index .posts article .entry-meta {
    color: #AAA;
    font-size: 0.9em; }
  main.page.index .posts article .excerpt p {
    margin: 0; }

body.login {
  background-color: #134282; }
  body.login div#login h1 a {
    background-image: url(../avatar.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px; }
