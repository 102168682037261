// Put SCSS for the front page here
main.front {

	.featured {
		position: relative;

		display: flex;
		justify-content: flex-end;

		background-position: left center;
		background-repeat: no-repeat;
		background-size: cover;

		@media (max-width: 900px) {
			justify-content: flex-start;
			background-position: top left;
			background-size: auto;
		}

		&::before {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;

			display: block;
			height: 100%;
			width: 100%;

			content: "";

			background-image: linear-gradient(to right, rgba($color-blue-background, 0), rgba($color-blue-background, 0) 33%, rgba($color-blue-background, 0.9) 66%, rgba($color-blue-background, 1));

			@media (max-width: 900px) {
				font-size: 2.8rem;
				background-image: linear-gradient(to bottom, rgba($color-blue-background, 0), rgba($color-blue-background, 0) 33%, rgba($color-blue-background, 1) 66%, rgba($color-blue-background, 1));
			}
		}

		.content {
			position: relative;
			z-index: 20;
			width: 40%;

			padding: 4rem 2rem 4rem 0;

			@media (max-width: 900px) {
				padding: 22rem 2rem 2rem;
				width: 100%;
			}

			h1 {
				font-size: 2.5rem;
				color: $color-blue;

				@media (max-width: 768px) {
					font-size: 2rem;
				}
			}

			p {
				margin: 0 0 2rem;

				font-size: 1.2rem;
				line-height: 2em;

				@media (max-width: 900px) {
					line-height: 1.4em;
				}
			}
		}
	}


	.sign-up {
		display: flex;

		padding: 2rem;

		.icon {
			margin-right: 2rem;

			&::before {
				content: "\f0f6";
				font-size: 6rem;
				font-family: fontawesome;
				color: $color-blue-background;
			}

			@media (max-width: 900px) {
				display: none;
			}
		}
		
		.gform_wrapper {
			display: flex;
			flex-grow: 1;

			> form {
				display: flex;
				flex-grow: 1;

				margin: 0;

				@media (max-width: 900px) {
					flex-wrap: wrap;
				}

				.gform_heading {
					display: flex;
					align-items: center;

					margin-right: 2rem;

					@media (max-width: 900px) {
						width: 100%;
						margin-right: 0;
						margin-bottom: 1rem;
					}

					.gform_title {
						margin: 0;

						color: $color-blue;
						font-size: 1.625rem;
					}
				}

				.gform_body {
					display: flex;
					flex-grow: 1;

					@media (max-width: 900px) {
						margin-bottom: 1rem;
					}

					.gform_fields {
						display: flex;
						flex-wrap: nowrap;

						width: 100%;

						@media (max-width: 480px) {
							flex-direction: column;
						}

						label {

							.gfield_required {
								display: none;
							}
						}
					}
				}

				.gform_footer {
					display: flex;
					align-items: center;
				}
			}
		}
	}



	.events-news {
		display: flex;
		flex-wrap: wrap;

		> div {
			position: relative;

			flex-grow: 1;
			flex-basis: 30rem;

			padding: 5rem 4rem;

			background-size: cover;

			@media (max-width: 900px) {
				padding: 2rem 1rem;
			}
		}

		> .events {
			padding-left: 8rem;

			background-image: url(../images/events-background.jpg);
			background-position: right center;

			@media (max-width: 900px) {
				padding-left: 1rem;
			}

			h2::before {
				content: "\f073";
			}
		}

		> .news {
			padding-left: 10rem;

			background-image: url(../images/news-background.jpg);
			background-position: left center;

			@media (max-width: 900px) {
				padding-left: 1rem;
			}

			h2::before {
				content: "\f1ea";
			}
		}


		h2 {
			position: relative;

			margin: 0 0 1.5rem;

			font-size: 2.5rem;
			color: #FFF;

			&::before {
				position: absolute;
				bottom: 0;
				right: calc(100% + 1.5rem);

				display: block;

				color: $color-blue;
				font-size: 4rem;
				font-family: fontawesome;

				@media (max-width: 900px) {
					display: none;
				}
			}
		}


		ul {
			margin: 0 0 2rem;

			> li {
				display: flex;
				align-items: flex-start;

				&:not(:last-of-type) {
					margin-bottom: 2rem;
				}

				&.no-events {
					display: block;
					padding: 1rem;

					font-size: 1.6rem;

					background-color: #FFF;
				}

				.date {
					display: flex;
					flex-direction: column;
					align-items: center;

					margin-right: 1px;
					padding: 1rem;

					text-align: center;
					text-transform: uppercase;

					background-color: $color-blue-background;
					cursor: default;

					font-family: $font-title;

					.day {
						font-size: 3rem;
					}

					.month {
						font-size: 1.6rem;
					}
				}

				.content {
					position: relative;
					flex-grow: 1;
					display: block;

					padding: 1rem;
					padding-right: 4rem;

					background-color: #FFF;
					color: $color-text;

					&::after {
						position: absolute;
						top: 50%;
						right: 1.5rem;

						transform: translateY(-50%);
						transition: right 0.2s ease;

						font-size: 4rem;
						font-family: fontawesome;
						content: "\f105";
						color: $color-melon;
					}

					&:hover::after {
						right: 1rem;
					}

					.title {
						margin: 0 0 0.5rem;

						line-height: 1.2em;
						font-size: 1.625rem;
						font-family: $font-title;
					}

					.time {
						margin: 0 0 0.5rem;

						&::before {
							display: inline-block;
							margin-right: 0.325rem;

							font-family: fontawesome;
							content: "\f017";
						}

						span {
							color: $color-blue;
						}
					}

					.location {

						&::before {
							display: inline-block;
							margin-right: 0.325rem;

							font-family: fontawesome;
							content: "\f041";
						}

						span {
							color: $color-blue;
						}
					}

					.read-more {
						color: $color-blue;

						&::before {
							display: inline-block;
							margin-right: 0.325rem;

							font-family: fontawesome;
							content: "\f06e";
							color: $color-text;
						}
					}
				}
			}
		}
	}


	.twitter-quote {
		display: flex;
		flex-wrap: wrap;

		background-color: $color-blue-background;

		> div {
			display: flex;
			flex-grow: 1;
			flex-basis: 30rem;

			padding: 4rem;

			@media (max-width: 900px) {
				padding: 2rem;
			}
		}

		.twitter {
			display: flex;
			justify-content: flex-end;

			.twitter-feed {
				margin: 0 auto;
			}
		}

		.quote {
			display: flex;
			align-items: center;

			blockquote {
				position: relative;
				font-weight: 400;

				padding-left: 6rem;

				@media (max-width: 900px) {
					margin-top: 6rem;
					padding-left: 0;
					text-align: center;
				}

				&::before {
					position: absolute;
					top: 0;
					left: 0;

					display: block;

					height: 5rem;
					width: 5rem;

					text-align: center;
					font-size: 15rem;
					line-height: 12rem;
					font-family: Helvetica, Arial, sans-serif;
					content: "“";
					color: $color-blue;

					@media (max-width: 900px) {
						top: inherit;
						bottom: 100%;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				
				p {
					margin: 0;

					font-size: 1.625rem;
					line-height: 1.6em;
				}

				cite {
					display: block;

					padding: 1rem 0 0;

					font-size: 1rem;
					color: $color-blue;

					&::before {
						display: inline-block;
						margin-right: 0.5rem;

						content: "\2014";
					}
				}
			}
		}
	}


	.infographic {
		padding: 6rem 2rem 4rem;

		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		@media (max-width: 900px) {
			padding: 3rem 2rem 2rem;
		}

		.points {
			margin: 0 0 4rem;
		}

		.point {
			position: relative;
			z-index: 100;

			margin: -2rem auto 0;
			padding: 8rem 2rem 2rem;

			width: #{($row-width / 12) * 5};
			max-width: 100%;

			background-color: #FFF;

			svg.background {
				position: absolute;
				z-index: 8;
				top: 0;
				left: 0;

				display: block;
				height: 100%;


				image {
					width: 100%;
					height: 100%;
				}
			}

			.background-wash {
				position: absolute;
				z-index: 9;
				top: 0;
				left: 0;

				height: 100%;
				width: 100%;
			}

			.content {
				position: relative;
				z-index: 10;

				line-height: 1.4em;
				color: #FFF;
				font-size: 1.625em;
				font-family: $font-title;
				text-align: center;
			}



			$margin-offset: #{($row-width / 12) * 2};
			&:nth-child(odd) {
				margin-left: $margin-offset;

				@media (max-width: 1024px) {
					width: 100%;
					margin-top: 0;
					margin-bottom: 1rem;
					margin-left: 0;
					margin-right: 0;
				}

				svg.background {
					left: -2rem;
					width: calc(100% + 2rem);
				}
			}

			&:nth-child(even) {
				margin-right: $margin-offset;

				@media (max-width: 1024px) {
					width: 100%;
					margin-top: 0;
					margin-bottom: 1rem;
					margin-left: 0;
					margin-right: 0;
				}

				svg.background {
					left: 0;
					width: calc(100% + 2rem);
				}
			}

			&:nth-child(2) {
				margin-right: #{($row-width / 12) * 1.5};
				@media (max-width: 1024px) { margin-right: 0; }
			}

			&:nth-child(3) {
				margin-left: #{($row-width / 12) * 1.5};
				@media (max-width: 1024px) { margin-left: 0; }
			}
		}

		footer {
			text-align: center;

			h2 {
				margin-bottom: 2rem;

				font-size: 3.75rem;

				@media (max-width: 900px) {
					font-size: 2.6rem;
				}
			}
		}
	}
}