%text-input {
	padding: 0.5rem 0;
	width: 100%;

	border: 0;

	outline: none;
}

input[type="text"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
textarea {
	@extend %text-input;
}
// Hide Honey pot
.gform_validation_container {
	//Accessiblity Hidding
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

.gform_fields {
	display: flex;
	flex-wrap: wrap;
}

.gfield {
	flex-grow: 1;

	position: relative;

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0.625rem 0;
	padding-right: 2rem;
	width: 100%;
	max-width: 100%;

	@media (max-width: 768px) {
		padding-right: 0;
	}

	&.gfield_error {
		padding-right: 0;
		border: 1px solid $color-error-bg;

		.validation_message {
			align-self: flex-start;
			margin: 0 0 0 0.5rem;
			padding: 0.5rem;
			font-size: 0.9rem;
			line-height: 1.1rem;
			color: $color-error;
			background-color: $color-error-bg;
		}
	}

	// &.gf_left_half,
	// &.gf_right_half,
	&.half-col {
		@media (min-width: 768px) {
			width: 50%;
		}
	}

	label {
		// position: absolute;
		// top: 0.5rem;

		font-size: 1rem;
		color: $color-text;

		transition: all 0.3s ease;
	}

	select {
		min-width: 300px;
	}

	&[data-animate] label {
		position: absolute;
		top: 0.5rem;
	}

	.gfield_description {
		font-size: 0.9rem;
		font-style: italic;
	}

	.clear-multi {
		display: flex;
		flex-wrap: wrap;

		> * {
			// flex-shrink: 0;
			// flex-grow: 1;
			margin: 0 1rem 1rem;
		}

		select {
			border: 1px solid #000;
		}
	}

	.ginput_container {
		position: relative;
		width: 100%;

		&::before,
		&::after {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;

			content: "";
		}

		&::before {
			z-index: 90;
			width: 100%;

			border-bottom: 1px solid $color-text;
		}

		&::after {
			z-index: 100;
			left: 50%;

			width: 0;

			transition: all 0.2s ease;

			border-bottom: 2px solid $color-melon;
		}

		&.ginput_container_radio,
		&.ginput_container_checkbox {
			&::before,
			&::after {
				display: none;
			}

			> ul {
				list-style: none;
				margin: 0;
				padding: 0.5rem 0 0 0;

				li {
					display: flex;
					align-items: center;
					margin: 0 0 0.25rem;
				}
			}

			label {
				position: relative;
				top: inherit;
			}

			input[type="radio"],
			input[type="checkbox"] {
				display: inline-block;
				margin-right: 0.5rem;
			}
		}

		&.ginput_container_date,
		&.ginput_container_name {
			display: flex;
			flex-wrap: wrap;

			&::before {
				display: none;
			}

			span {
				display: block;

				@media (min-width: 768px) {
					width: 50%;
				}

				&:not(:last-of-type) {
					padding-right: 2rem;
				}
			}

			input {
				border-bottom: 1px solid #000;
			}
		}

		&.ginput_container_address {
			display: flex;
			flex-wrap: wrap;

			span {
				flex-shrink: 0;
				display: block;

				@media (min-width: 768px) {
					width: 50%;
				}

				&:not(:last-of-type) {
					padding-right: 2rem;
				}
			}

			select {
				display: block;
				width: 100%;

				border: 1px solid #000;
			}

			input {
				border-bottom: 1px solid #000;
			}
		}

		&.ginput_container_time {
			&::before {
				display: none;
			}

			input {
				border-bottom: 1px solid #000;
			}
		}
	}

	&[data-animate][data-status="focus"] {
		.ginput_container {
			&::after {
				left: 0;

				width: 100%;
			}
		}
	}

	&[data-animate][data-status="focus"],
	&[data-animate][data-filled="true"] {
		label {
			transform: translateY(-1rem);

			font-size: 0.8rem;
		}
	}

	&[data-animate][data-status="focus"] {
		label {
			color: $color-melon;
		}
	}
}

.ui-datepicker {
	background-color: #fff;

	.ui-datepicker-header {
		padding: 0.25rem;

		font-size: 1rem;
		background-color: $color-smalt;
		text-align: center;

		a {
			padding: 4px 8px;
		}
	}

	.ui-datepicker-calendar {
		thead tr th {
			padding: 4px;

			color: #333;
			font-size: 1rem;
		}

		tbody tr td {
			text-align: center;
			font-size: 1rem;

			border: 1px solid $color-smalt;

			a {
				display: block;
				padding: 5px;

				a {
					color: $color-blue;
				}

				&.ui-state-active {
					color: #333;
					font-weight: 700;
				}
			}
		}
	}
}
