.fact {
	display: flex;

	background-color: $color-blue-light;

	@media (max-width: 480px) {
		flex-direction: column;
	}

	.icon {
		position: relative;

		min-height: 10rem;
		min-width: 12rem;

		background-color: $color-blue;

		@media (max-width: 480px) {
			min-height: 5rem;
			min-width: 5rem;
		}

		&::before {
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			color: $color-blue-dark;
			font-family: fontawesome;
			font-size: 10rem;
			content: "\f05a";

			@media (max-width: 480px) {
				font-size: 5rem;
			}
		}

		&::after {
			position: absolute;
			z-index: 20;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			content: "Fact:";
			color: #FFF;
			font-size: 3.6rem;
			letter-spacing: 2px;
			font-family: $font-title;
			text-transform: uppercase;

			@media (max-width: 480px) {
				font-size: 2.8rem;
			}
		}
	}

	.text {
		display: flex;
		align-items: center;
		flex-grow: 1;

		padding: 2rem;

		font-size: 1.625rem;

		@media (max-width: 480px) {
			padding: 1rem 1rem 2rem;

			font-size: 1.2rem;
			text-align: center;
		}
	}
}