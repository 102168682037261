body > header.desktop {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;

	display: flex;
	width: 100%;

	background-color: $color-gray-light;

	@include with-touch-nav {
		display: none;
	}

	a.logo {
		display: block;

		width: 400px;
		min-width: 360px;
		min-height: 100px;

		font-size: 0;
		color: transparent;

		background-repeat: no-repeat;
		background-position: center;
		background-color: #fff;
		background-image: url(../images/header-logo.png);

		@media (max-width: 1024px) {
			width: 240px;
			min-width: 240px;
			background-size: 90% auto;
		}
	}

	a:not(.logo) {
		position: relative;
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;

		padding: 1.5rem 1rem 1rem;

		color: $color-text;
		font-family: $font-title;
		text-align: center;

		transition: all 0.2s ease;

		&::before {
			display: block;
			margin-bottom: 0.625rem;

			font-size: 1.6rem;
			font-family: fontawesome;
			color: $color-gray;
			content: "i";

			transition: all 0.2s ease;
		}

		&:hover {
			background-color: $color-gray;

			&::before {
				color: $color-melon;
			}
		}
	}

	a.donate {
		flex-grow: 0;

		padding-left: 2rem;
		padding-right: 2rem;

		background-color: $color-blue-light;

		&::before {
			content: "\f004";
			color: $color-melon;
		}

		&:hover {
			background-color: $color-blue;
		}
	}

	nav {
		display: flex;

		&.primary {
			flex-grow: 1;

			> div {
				display: flex;
				width: 100%;
			}

			.menu {
				display: flex;
				width: 100%;

				> li {
					position: relative;
					display: flex;
					flex-grow: 1;

					&.info-circle > a::before {
						content: "\f05a";
					}
					&.list > a::before {
						content: "\f0ca";
					}
					&.newspaper > a::before {
						content: "\f1ea";
					}
					&.calendar > a::before {
						content: "\f073";
					}
					&.user-plus > a::before {
						content: "\f234";
					}
					&.envelope > a::before {
						content: "\f0e0";
					}
					&.search > a::before {
						content: "\f002";
					}

					&.current-menu-item > a {
						&::after {
							position: absolute;
							bottom: 0rem;
							left: calc(50% - 0.5rem);
							content: "";

							border-top: 0;
							border-left: 0.5rem solid transparent;
							border-right: 0.5rem solid transparent;
							border-bottom: 0.5rem solid $color-melon;
						}
					}

					> .sub-menu {
						position: absolute;
						top: 100%;
						left: 0;

						display: none;
						min-width: 200px;

						a {
							display: flex;
							width: 100%;
							align-items: flex-start;

							padding: 1rem;

							text-align: left;
							line-height: 1.2;
							background-color: rgba($color-gray, 1);

							&::before {
								display: none;
							}

							&:hover {
								background-color: darken($color-gray, 10%);
							}
						}

						.sub-menu a {
							padding-left: 2rem;
							font-size: 0.9em;
						}
					}

					&:hover > a {
						background-color: $color-gray;

						&::before {
							color: $color-melon;
						}
					}

					&:hover > .sub-menu {
						display: block;
					}
				}
			}
		}

		&.social {
			justify-content: space-around;
			flex-direction: column;

			a {
				display: flex;
				justify-content: center;
				padding: 0 1rem;

				font-size: 0;
				color: transparent;

				&::before {
					display: flex;
					margin: 0;

					font-family: fontawesome;
					content: "";
					font-size: 1.2rem;

					color: $color-text;
				}

				&:hover {
					background-color: $color-gray-light;
				}

				&.facebook::before {
					content: "\f09a";
				}
				&.instagram::before {
					content: "\f16d";
				}
				&.twitter::before {
					content: "\f099";
				}
			}
		}
	}

	$popup-speed: 0.5s;
	.search-popup {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 4rem;
		height: 100vh;
		width: 100%;

		visibility: hidden;

		transform: translateY(-100%);
		opacity: 0;
		transition: opacity $popup-speed ease, transform $popup-speed ease,
			visibility 0s linear;

		&[data-status="active"] {
			visibility: visible;

			transform: translateY(0);
			opacity: 1;
		}

		.overlay {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;

			display: block;
			height: 100%;
			width: 100%;

			background-color: rgba($color-blue, 0.8);
		}

		$close-button-size: 6rem;
		.close {
			position: absolute;
			z-index: 20;
			top: 0;
			right: 0;

			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0rem;
			width: $close-button-size;
			height: $close-button-size;

			color: #fff;
			font-size: $close-button-size;
			line-height: $close-button-size;

			background-color: transparent;
		}

		.input-wrapper {
			position: relative;
			z-index: 20;

			display: flex;
			margin: 0 0 2rem;
			width: 100%;

			input[type="text"] {
				display: block;
				padding: 1rem;

				text-align: center;
				font-size: 2rem;

				background-color: rgba(#fff, 0.9);
			}

			button[type="submit"] {
			}
		}
	}
}
