body > footer {
	position: relative;

	display: flex;
	padding: 4rem 2rem;

	color: #FFF;
	background-color: $color-footer-background;

	@media (max-width: 900px) {
		flex-direction: column;
		text-align: center;
		padding: 2rem;
	}
	
	#back-to-top {
		position: absolute;
		top: -1rem;
		left: 50%;

		display: flex;
		align-items: center;
		justify-content: center;

		height: 2rem;
		width: 5rem;

		transform: translateX(-50%);

		background-color: #FFF;

		&::before {
			font-family: fontawesome;
			content: "\f106";
			font-size: 1.8rem;
		}
	}

	.colophon {
		width: 33%;

		@media (max-width: 900px) {
			width: 100%;
			margin: 0 0 1rem;
		}
	}

	nav {
		display: flex;
		width: 34%;


		@media (max-width: 900px) {
			width: 100%;
			margin: 0 0 1rem;
		}

		> div {
			width: 100%;
		}

		ul {
			text-align: center;
		}

		ul > li {
			display: inline-block;

			@media (max-width: 900px) {
				display: block;
			}

			&:not(:last-of-type) {
				margin-right: 0.325rem;
				padding-right: 0.325rem;

				&::after {
					padding-left: 0.75rem;
					content: "/";
				}

				@media (max-width: 900px) {
					margin-right: 0;
					padding-right: 0;
					padding-top: 0.25rem;
					padding-bottom: 0.25rem;

					&::after {
						display: none;
					}
				}
			}

			> a {
				
			}
		}
	}

	.attribution {
		width: 33%;
		text-align: right;

		@media (max-width: 900px) {
			width: 100%;
			text-align: center;
			font-size: 0.9em;
		}
	}
}