/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

body {
	font-family: $font-body;
	color: $color-text;

	background-color: $color-footer-background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-title;
	margin-bottom: 1rem;
	line-height: 1.4em;
}

a {
	outline: none;
	color: $color-melon;
}

main.page {
	background-color: #fff;
}

main.page:not(.front) {
	position: relative;
	z-index: 10;

	display: flex;
	flex-wrap: wrap;

	.primary {
		flex-grow: 1;
		flex-basis: 36rem;

		margin-top: -2rem;
		padding: 2rem 4rem 2rem #{$row-width / 12};

		background-color: #fff;

		@media (max-width: 768px) {
			padding: 2rem;
		}

		@media (max-width: 460px) {
			padding: 1rem;
		}

		.column-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -1rem;

			> .column {
				flex-grow: 1;
				flex-basis: 24rem;
				margin: 0 1rem 1rem;
			}
		}
	}

	.sidebar {
		display: flex;
		flex-basis: 16rem;
		flex-shrink: 0;

		background-color: $color-gray-light;

		@media (max-width: 52rem) {
			flex-basis: auto;
			width: 100%;
		}
	}
}

.page-header {
	position: relative;
	z-index: 9;

	padding: 6rem 0 8rem;

	background-image: url("../images/page-header-bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width: 640px) {
		padding: 2rem 0 4rem;
	}

	&::before {
		position: absolute;
		z-index: 9;
		top: 0;
		left: 0;

		display: block;
		height: 100%;
		width: 100%;
		content: "";

		background-color: rgba($color-blue-dark, 0.6);
	}

	h1 {
		position: relative;
		z-index: 10;

		margin: 0;
		padding-left: #{$row-width / 12};
		padding-right: #{$row-width / 12};

		color: #fff;
		text-shadow: 0 1px 2px rgba(#000, 0.5);
		font-size: 3rem;
	}
}

%button {
	display: inline-block;

	padding: 1.5rem 3rem;

	color: $color-text;
	text-transform: uppercase;
	text-decoration: none;
	background-color: $color-melon;

	transition: background 0.2s ease;

	&:hover {
		background-color: lighten($color-melon, 5%);
	}
}

.button,
button {
	@extend %button;
}

@mixin button {
	display: inline-block;

	padding: 1.5rem 3rem;

	color: $color-text;
	text-transform: uppercase;
	text-decoration: none;
	background-color: $color-melon;

	transition: background 0.2s ease;

	&:hover {
		background-color: lighten($color-melon, 5%);
	}
}
