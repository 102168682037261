.luvu-slider {
	&__container {
		height: 600px;
		background-color: $color-text;
	}

	&__slide {
		position: relative;

		box-sizing: border-box;
	}

	&__image {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 100%;

		box-sizing: inherit;

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;

			display: block;
			width: 100%;
			height: 50%;

			// content: "";

			background-image: linear-gradient(to bottom, rgba(#FFF, 0), rgba(#FFF, 0.8));
		}
	}

	&__wash {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;

		display: flex;
		width: 100%;
		height: 100%;

		opacity: 0;
	}

	&__content {
		box-sizing: border-box;
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;

		display: flex;
		padding: 3rem 5rem;
		width: 100%;
		height: 100%;

		color: $color-text;
	}

	&__position {
		width: 100%;

		display: flex;
		flex-direction: column;

		&--left-bottom {
			align-items: flex-start;
			justify-content: flex-end;
			text-align: left;
		}
		&--center-bottom {
			align-items: center;
			justify-content: flex-end;
			text-align: center;
		}
		&--right-bottom {
			align-items: flex-end;
			justify-content: flex-end;
			text-align: right;
		}

		&--left-center {
			align-items: flex-start;
			justify-content: center;
			text-align: left;
		}
		&--center-center {
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		&--right-center {
			align-items: flex-end;
			justify-content: center;
			text-align: right;
		}

		&--left-top {
			align-items: flex-start;
			justify-content: flex-start;
			text-align: left;
		}
		&--center-top {
			align-items: center;
			justify-content: flex-start;
			text-align: center;
		}
		&--right-top {
			align-items: flex-end;
			justify-content: flex-start;
			text-align: right;
		}
	}

	&__headline {
		margin: 0;
		max-width: 66%;

		font-family: $font-title;
		font-size: 3rem;

		&:not(:last-child) {
			margin: 0 0 1rem;
		}

		@media (max-width: 768px) {
			max-width: 100%;

			font-size: 2rem;
		}
	}

	&__button {
		@include button;
	}

	&__nav {
		position: absolute;
		z-index: 3;
		top: 0;

		width: 4rem;
		height: 100%;

		transition: all 0.2s ease-in-out;
		cursor: pointer;

		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0.8);

			font-family: fontawesome;
			font-size: 2.6rem;
			color: #FFF;
			text-shadow: 0 1px 2px rgba(#000, 0.5);

			transition: all 0.2s ease-in-out;
			opacity: 0;
		}

		&:hover {
			background-color: rgba(#000, 0.3);

			&::before {
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
			}
		}

		&--previous {
			left: 0;

			&::before {
				content: "\f104";
			}

			&:hover {
				// background-image: linear-gradient(to right, rgba(#000, 0.4), rgba(#000, 0));
			}
		}

		&--next {
			right: 0;

			&::before {
				content: "\f105";
			}

			&:hover {
				// background-image: linear-gradient(to left, rgba(#000, 0.4), rgba(#000, 0));
			}
		}
	}

	&__pagination {
		position: absolute;
		z-index: 3;
		bottom: 0.5rem;
		left: 0;

		display: flex;
		justify-content: center;
		padding: 0.5rem;
		width: 100%;

		$bullet: 0.75rem;
		.swiper-pagination-bullet {
			display: block;
			margin: 0 0.5rem;
			height: $bullet;
			width: $bullet;

			cursor: pointer;

			border-radius: $bullet;

			background-color: rgba(#999, 0.5);

			&-active {
				background-color: rgba($color-blue-dark, 1);
			}
		}
	}
}