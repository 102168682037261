.pagination {
	display: flex;

	> * {
		display: inline-block;
		padding: 1rem;

		&:not(:last-child) {
			margin-right: 1rem;
		}
	}

	span {
		cursor: default;
		color: $color-gray-dark;
		background-color: $color-gray-light;

		&.dots {
			padding: 1rem 0.5rem;
			background-color: transparent;
		}
	}

	a {
		color: #FFF;
		background-color: $color-melon;
		transition: background 0.2s ease;

		&:hover {
			background-color: lighten($color-melon, 5%);
		}
	}
}