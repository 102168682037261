gecko-slideshow {
	display: block;
	position: relative;
	z-index: 1;

	&:not([data-bound]) {
		visibility: hidden;
	}

	> .viewport {
		width: 100%;
		height: 600px;

		> .slides {
			position: relative;
			width: 100%;
			height: 100%;
		}
	}

	.overlay {
		position: absolute;
		z-index: 1000;
		bottom: 0;
		left: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;

		width: 100%;
		height: 66%;

		padding: 2rem 2rem 3rem;

		background-image: linear-gradient(to bottom, rgba(#FFF, 0), rgba(#FFF, 0.8));

		.caption {
			display: block;

			margin: 0 0 2rem;
			
			text-align: center;
			font-family: $font-title;
			font-size: 3rem;

			@media (max-width: 768px) {
				font-size: 2rem;
			}
		}
	}
}