%wysiwyg, .wysiwyg {
	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 2.3rem;
	}

	h3 {
		font-size: 1.9rem;
	}

	h4 {
		font-size: 1.4rem;
	}

	p {
		line-height: 1.6;
		margin: 1rem 0;
	}

	b, strong {
		font-weight: bold;
	}

	i, em {
		font-style: italic;
	}

	ul {
		list-style: disc;
		margin-left: 1rem;

		&.gform_fields {
			margin-left: 0;
		}
	}

	ol {
		counter-reset: ol;

		> li {
			counter-increment: ol;

			&::before {
				content: counter(ol);
				margin-right: 0.5rem;
			}
		}
	}

	ul li,
	ol li {
		line-height: 1.6;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}
	}

	
	hr {
		position: relative;
		height: 0;
		margin: 3rem 0;

		border: 0;
		border-bottom: 0.5rem solid $color-gray-light;

		&::before,
		&::after {
			position: absolute;
			top: 0;

			display: block;
			height: 0.5rem;

			content: "";
			background-color: $color-gray-light;
		}

		&::before {
			right: 100%;
			width: 50vw;
		}

		&::after {
			left: 100%;
			width: 4rem;
		}

		@media (max-width: 768px) {
			&::before,
			&::after {
				width: 2rem;
			}
		}

		@media (max-width: 460px) {
			&::before,
			&::after {
				width: 1rem;
			}
		}
	}


	.pullquote {
		position: relative;
		padding: 2rem 2rem 2rem 0;

		font-size: 1.4rem;
		font-style: italic;
		line-height: 1.6em;
		background-color: $color-blue-background;

		&::before {
			position: absolute;
			top: 0;

			display: block;
			height: 100%;

			content: "";
			background-color: $color-blue-background;
		}

		&::before {
			right: 100%;
			width: 50vw;
		}
	}



	.alignright {
		float: right;
		margin: 2rem 0 2rem 2rem;
	}

	.alignleft {
		float: left;
		margin: 2rem 2rem 2rem 0;
	}

	.wp-caption-text {
		padding: 0.5rem 1rem;

		font-size: 0.9em;
		text-align: center;
	}


	img {
		max-width: 100%;
		height: auto;
	}


	.gform_wrapper {

		.validation_error {
			margin: 0.5rem 0;
			padding: 0.5rem 1rem;
			color: $color-error;
			background-color: $color-error-bg;
		}
	}
}