body > aside.touch-menu {
	position: fixed;
	top: 0;
	left: 0;

	z-index: 105;
	width: 80vw;
	max-width: 25rem;
	height: 100vh;

	display: flex;
	flex-direction: column;

	background-color: white;

	transition: left 0.3s ease-in-out;

	&[hidden] {
		left: -100%;
	}

	header {
		flex-shrink: 0;

		padding: 1rem;

		border-bottom: 1px solid black;
	}

	nav {
		flex-grow: 1;
		overflow-y: auto;

		.menu {

			> li {
				display: block;

				&:not(:last-of-type) {
					border-bottom: 1px solid $color-gray;
				}

				a {
					display: block;
					transition: background-color 0.2s ease-in-out;
				}

				> a {
					padding: 1rem;

					font-size: 1.4rem;

					&:focus,
					&:hover {
						background-color: rgba($color-gray, 0.2);
					}

					&:active {
						background-color: rgba(black, 0.3);
					}
				}

				.sub-menu {

					> li {
						border-top: 1px solid rgba($color-gray, 0.8);

						> a {
							padding: 0.5rem 1.75rem;
							background-color: rgba($color-gray, 0.2);

							&:focus, &:hover {
								background-color: rgba($color-gray, 0.4);
							}
						}
					}
				}
			}
		}
	}

	footer {
		flex-shrink: 0;

		border-top: 1px solid black;
		padding: 1rem;
	}
}